

import { defineComponent, ref, onMounted, computed, onUpdated } from "vue";
import ExportCustomerModal from "@/components/modals/forms/ExportCustomerModal.vue";
import AddProductBrandModal from "@/components/modals/forms/AddProductBrandModal.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";


export default defineComponent({
  name: "customers-listing",
  components: {
    ExportCustomerModal,
    AddProductBrandModal, 
  },
  
  setup() {
    const count = ref(0);
    const store = useStore();
    const checkedCompany = ref([]);    
    const loadingData = ref<boolean>(true);
    const searchTearm = ref('');

    interface IBrand {
      brand_id: number,
      active: {
        label: string;
        color: string;
      },      
      brand_name: string,
      company_name: string,
    }

    var paginationData = ref({})
    
    paginationData.value = {      
         start : 0,
          end : 0,
          total: 0,        
        perPage : 5,
        activePage : 1,
        totPage : 1,        
        pageRange : [1]
    }

    const refreshData =() => {      
      paginationData.value['activePage']  = 1;      
      getBrandList(searchTearm.value)
    }

    const changePageChange = (page) => {      
      paginationData.value['activePage']  = page;      
      getBrandList(searchTearm.value)
    }
    
    var tableData = ref<Array<IBrand>>([]);
  
     const getBrandList = async (data) => {
      
      loadingData.value = true;

      try {

        var values = { "brand_id"  :  0, "search_term" : data, "page"  : parseInt(paginationData.value['activePage']), "records_per_page" : parseInt(paginationData.value['perPage']) }

        await store.dispatch(Actions.CUST_GET_PRODUCT_BRAND, values).then(({ data }) => {

          console.log(data);
          
          tableData.value = ([]);

          // set pagination
          paginationData.value['total'] = data.total_records;        
          paginationData.value['start'] = data.records_from
          paginationData.value['end'] = data.records_upto
          paginationData.value['activePage'] = data.page;
          paginationData.value['totPage']  = data.total_pages
          paginationData.value['pageRange'] = []
          for (let index =  data.bar_range_start; index < data.bar_range_end; index++) {
            paginationData.value['pageRange'].push(index)
          }
          //endpagination          
          var resultsM = ref<Array<IBrand>>([])          
          var active_label = ""
          var active_color = ""        

          for (let j = 0; j < data.result_list.length; j++) {


            if (data.result_list[j]['active']){
                active_label = "Yes";
                active_color = "success";                  
            }else{
                active_label = "No";
                active_color = "danger";
            }
            
            resultsM.value = Array({
              brand_id : data.result_list[j]['brand_id'],
              active: {
                label: active_label,
                color: active_color
              },

              brand_name: data.result_list[j]['brand_name'],
              company_name: data.result_list[j]['company_name'],
            })

            tableData.value.splice(j, resultsM.value.length, ...resultsM.value);            
          }

          loadingData.value = false;

        }).catch(({ response }) => {

          tableData.value = [];
          loadingData.value = false;

       });

      } catch (e) {
        console.log(e);
      }
    };

      onUpdated(() => {          
        // console.log("aaap");
        // console.log(list_data);
      });

      const initCustomers = ref<Array<IBrand>>([]);                      

      onMounted( async () => {
        await getBrandList(searchTearm.value)
        setCurrentPageBreadcrumbs("Product Brand", []);
        initCustomers.value.splice(0, tableData.value.length, ...tableData.value);        
        console.log(tableData.value.length);
      });  

      const deleteFewCustomers = () => {
        checkedCompany.value.forEach((item) => {
          deleteCustomer(item);
        });
        checkedCompany.value.length = 0;
      };

      const deleteCustomer = (id) => {
        for (let i = 0; i < tableData.value.length; i++) {
          if (tableData.value[i].brand_id === id) {
            tableData.value.splice(i, 1);
          }
        }
      };

      const search = ref<string>("");
       const searchItems = () => {
          searchTearm.value = search.value;
          getBrandList(search.value);
          console.log(search.value);          
      };

      const searchingFunc = (obj, value): boolean => {        
        for (let key in obj) {          
          if (!Number.isInteger(obj[key]) && !(typeof obj[key] === "object")) {            
            if (obj[key].indexOf(value) != -1) {
              
              return true;
            }
          }
        }
        return false;
      };          
       
    return {
      tableData,      
      deleteCustomer,
      search,
      searchItems,
      checkedCompany,
      deleteFewCustomers,
      count,      
      getBrandList,       
      paginationData,
      changePageChange,
      refreshData,
      loadingData
    };  

  }  

});
